// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import bslogo from "assets/images/bs-logo.png";
import logoCT from "assets/images/logo-ct-dark.png";



function Footer() {
  return (
    <MKBox component="footer" py={6}>
      <Container>
        <Grid container>
          <Grid
            item
            xs={12}
            lg={4}
            textAlign={{ xs: "center", lg: "left" }}
            mr="auto"
            mb={{ xs: 3, lg: 0 }}
          >
            <MKTypography variant="h6" textTransform="uppercase" mb={{ xs: 2, lg: 3 }}>
              Swiss Tamils Aid{" "}
      <MKTypography
        component="a"
        href=""
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        <img src={logoCT} alt="Logo" style={{ height: '15px', marginLeft: '0px', marginBottom: '-2px', verticalAlign: 'baseline' }} />
      </MKTypography>
            </MKTypography>
            <Stack
              component="ul"
              direction="row"
              flexWrap="wrap"
              spacing={3}
              justifyContent={{ xs: "center", lg: "flex-start" }}
              pl={0}
              mb={3}
              sx={{ listStyle: "none" }}
            >
              <MKBox component="li">
                <MKTypography
                  variant="button"
                  fontWeight="regular"
                  opacity={0.8}
                  component={Link}
                  href="https://dev.tamils-aid.ch/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Home
                </MKTypography>
              </MKBox>
              <MKBox component="li">
                <MKTypography
                  variant="button"
                  fontWeight="regular"
                  opacity={0.8}
                  component={Link}
                  href="https://dev.tamils-aid.ch/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Medien
                </MKTypography>
              </MKBox>
              <MKBox component="li">
                <MKTypography
                  variant="button"
                  fontWeight="regular"
                  opacity={0.8}
                  component={Link}
                  href="https://dev.tamils-aid.ch/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Downloads
                </MKTypography>
              </MKBox>
              <MKBox component="li">
                <MKTypography
                  variant="button"
                  fontWeight="regular"
                  opacity={0.8}
                  component={Link}
                  href="https://dev.tamils-aid.ch/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Kontakt
                </MKTypography>
              </MKBox>
            </Stack>
            <MKTypography variant="button" opacity={0.8}>
              Copyright © <script>document.write(new Date().getFullYear())</script>2024 Swiss Tamils Aid implementiert durch{" "}
      <MKTypography
        component="a"
        href="https://www.barthansiva.ch"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        <img src={bslogo} alt="Logo" style={{ height: '15px', marginLeft: '0px', marginBottom: '-2px', verticalAlign: 'baseline' }} />
      </MKTypography> 
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={6} ml="auto" textAlign={{ xs: "center", lg: "right" }}>
            <MKTypography variant="body1" fontWeight="bold" mb={6} sx={{ fontSize: "1.125rem" }}>
              Wir helfen notleidende Menschen in Sri Lanka
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Footer;
