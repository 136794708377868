// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/Instagram";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logo-ct-dark.png";
import bslogo from "assets/images/bs-logo.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Swiss Tamils Aid",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <YouTubeIcon />,
      link: "https://www.instagram.com/swiss_tamils_aid?igsh=MTk2bHkyeGJ4ZXVvcA==",
    },
  ],
  menus: [
    {
      name: "Hauptsitz Luzern",
      items: [
        { name: "Verein Swiss Tamils Aid" },
        { name: "c/o Adrian Wirz" },
        { name: "Geissmattstrasse 16" },
        { name: "6004 Luzern" },
        { name: "Mail: adiwirz@yahoo.de", href: "mailto:adiwirz@yahoo.de"},
        
      ],
    },
    {
      name: "Büro Emmenbrücke",
      items: [
        { name: "Verein Swiss Tamils Aid" },
        { name: "Gerliswilstrasse 17" },
        { name: "Postfach 2018" },
        { name: "6032 Emmenbrücke" },
        { name: "Mail: info@tamils-aid.ch", href: "mailto:info@tamils-aid.ch"},
        
      ],
    },
    {
      name: "Spendenkonto",
      items: [
        { name: "Post Konto 61-399493-3" },
        { name: "IBAN Nummer CH53 0900 0000 6139 9493 3" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      Alle Rechte vorbehalten. Copyright &copy; {date} Swiss Tamils Aid implementiert durch{" "}
      <MKTypography
        component="a"
        href="https://www.barthansiva.ch"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        <img src={bslogo} alt="Logo" style={{ height: '15px', marginLeft: '0px', marginBottom: '-2px', verticalAlign: 'baseline' }} />
      </MKTypography>
      
    </MKTypography>
  ),
};
