// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";


// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// About Us page sections
import Counters from "pages/LandingPages/AboutUs/sections/Counters";
import Footer from "pages/LandingPages/AboutUs/sections/Footer";
import Subtitle from "./sections/Subtitle";
import Contact from "pages/Projects/ICT/sections/Contact";
import Strength from "pages/LandingPages/AboutUs/sections/Strength";



// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/5.png";

function AboutUs() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "/",
          label: "jetzt spenden",
          color: "info",
        }}
        transparent
        light
      />
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.info.main, 0.8),
                rgba(gradients.info.state, 0.8)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <Subtitle />
          <Container sx={{ mt: 6 }}>
        </Container>
        <Counters />
          <Container>
          <Grid container spacing={3}           justifyContent="center"
          >
          <MKTypography variant="h2">Die Gründung</MKTypography>
          <Divider sx={{ my: 6 }} />
          <MKTypography variant="h7">2006 wurde die Stiftung Tamils Aid gegründet, welche 2015 in den Verein Swiss Tamils Aid Schweiz überging. Der Hauptgrund für diese Veränderungen war die hohen administrativen Kosten, welche eine Stiftung verursacht. Der Zweck des Vereins ist: Hilfe für Waisenkinder und andere notleidende Personen in Sri Lanka, sowie Unterstützung und Förderung von Frauen aus ärmsten Verhältnissen.
          </MKTypography>
          </Grid>
        </Container>
        <Strength />

        <Container>
          <Grid container spacing={3}           justifyContent="center"
          >
          <MKTypography variant="h2">Wie ist der Verein Swiss Tamils Aid (STA) organisiert?</MKTypography>
          <Divider sx={{ my: 6 }} />
          </Grid>
          <MKTypography variant="h7">Der Verein STA hat 13 Vorstandsmitglieder, welche jeweils eine regionale STA Sektion leiten.<br /></MKTypography>
          <MKTypography variant="h7">Der Verein funktioniert hauptsächlich durch die ehrenamtliche Mitarbeit der Vereinsmitglieder und von freiwilligen Helfern in der Schweiz. In unserem Büro in Emmenbrücke ist zudem ein ehrenamtlicher Mitarbeiter (Administration). STA Schweiz plant Projekte, entscheidet über deren Umsetzung und sucht Spendengelder. Jährlich besuchen die Vereinsmitglieder auf eigene Kosten die Projekte in Sri Lanka. STA Schweiz registrierte sich ebenfalls als Hilfsorganisation in Sri Lanka. Unter dem Namen „STA Solidarity Foundation“ ist die Swiss Tamils Aid Sri Lanka somit ebenfalls rechtmäßig als Hilfswerk unter den gesetzlichen Bestimmungen in Sri Lanka registriert. Bei STA Sri Lanka sind vier Mitarbeiter festangestellt. Diese sind zuständig für die Umsetzung der Projekte, besuchen diese regelmässig und übernehmen das Monitoring. Dies bedeutet auch, dass monatliche Projektberichte, Abrechnungen etc. in die Schweiz gesandt werden.</MKTypography>
          <Divider sx={{ my: 6 }} />
        </Container>
        <Container>
          <Grid container spacing={3}           justifyContent="center"
          >
          <MKTypography variant="h2">Wie werden die Projekte finanziert?</MKTypography>
          <Divider sx={{ my: 6 }} />
          </Grid>
          <MKTypography variant="h7">Das Jahresbudget von STA Schweiz betrug in den letzten vier Jahren zwischen 40‘000 CHF 60‘000 CHF. Finanziert werden die Projekte durch Spenden von Privatpersonen und Stiftungen. Die Verwaltungskosten können durch die ehrenamtliche Arbeit der STA Mitglieder auf ein Minimum reduziert werden. Einzig die Mitarbeiter in Sri Lanka werden entlöhnt.<br /></MKTypography>
          <MKTypography variant="h7">Der Verein funktioniert hauptsächlich durch die ehrenamtliche Mitarbeit der Vereinsmitglieder und von freiwilligen Helfern in der Schweiz. In unserem Büro in Emmenbrücke ist zudem ein ehrenamtlicher Mitarbeiter (Administration). STA Schweiz plant Projekte, entscheidet über deren Umsetzung und sucht Spendengelder. Jährlich besuchen die Vereinsmitglieder auf eigene Kosten die Projekte in Sri Lanka. STA Schweiz registrierte sich ebenfalls als Hilfsorganisation in Sri Lanka. Unter dem Namen „STA Solidarity Foundation“ ist die Swiss Tamils Aid Sri Lanka somit ebenfalls rechtmäßig als Hilfswerk unter den gesetzlichen Bestimmungen in Sri Lanka registriert. Bei STA Sri Lanka sind vier Mitarbeiter festangestellt. Diese sind zuständig für die Umsetzung der Projekte, besuchen diese regelmässig und übernehmen das Monitoring. Dies bedeutet auch, dass monatliche Projektberichte, Abrechnungen etc. in die Schweiz gesandt werden.</MKTypography>
        </Container>
        </Card>
        <Contact />
        <Footer />
      </MKBox>
    </>
  );
}

export default AboutUs;
