import communityBuilding from "assets/images/community-building-main.jpg"; // Adjust the path if necessary
import test from "assets/images/STA_LOGO.png"

import ICT from "layouts/pages/projects/ict";
import ComingSoon from "layouts/pages/landing-pages/coming-soon";


const imagesPrefix =
  "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/material-design-system/presentation/sections";

export default [
  {
    title: "Diverse",
    description: " ",
    items: [
      {
        image: test,
        name: "Community Building Amman",
        route: "/pages/landing-pages/coming-soon",
        component: <ComingSoon />,      },
      {
        image: test,
        name: "ICT - Ausbildung Amman Illam",
        route: "/pages/projects/ict",
        component: <ICT />,
      },
      {
        image: test,
        name: "Poultry Farm",
        route: "/pages/landing-pages/coming-soon",
        component: <ComingSoon />,      },
      {
        image: test,
        name: "Multipurpose Shop Amman Illam",
        route: "/pages/landing-pages/coming-soon",
        component: <ComingSoon />,      },
      {
        image: test,
        name: "Kindergarten",
        route: "/pages/landing-pages/coming-soon",
    component: <ComingSoon />,
      },
      {
        image: test,
        name: "Amman Preschool",
        route: "/pages/landing-pages/coming-soon",
        component: <ComingSoon />,      },
    ],
  },
  {
    title: "Kinderheime",
    description: "",
    items: [
      {
        image: test,
        name: "Amman Illam",
        route: "/pages/landing-pages/coming-soon",
        component: <ComingSoon />,      },
      {
        image: test,
        name: "Shakti Illam",
        route: "/pages/landing-pages/coming-soon",
        component: <ComingSoon />,      },
    ],
  },
  {
    title: "Altersheim",
    description: " ",
    items: [
      {
        image: test,
        name: "Kilinochi Altersheim",
        route: "/pages/landing-pages/coming-soon",
        component: <ComingSoon />,      },
    ],
  },
  {
    title: "Berufsbildung",
    description: " ",
    items: [
      {
        image: test,
        name: "Beautician",
        route: "/pages/landing-pages/coming-soon",
    component: <ComingSoon />,
      },
      {
        image: test,
        name: "Köchinnen",
        route: "/pages/landing-pages/coming-soon",
    component: <ComingSoon />,
      },
      {
        image: test,
        name: "Schneiderinnen",
        route: "/pages/landing-pages/coming-soon",
        component: <ComingSoon />,      },
      {
        image: test,
        name: "Hospitality",
        route: "/pages/landing-pages/coming-soon",
        component: <ComingSoon />,      },

      {
        image: test,
        name: "Preschool Teacher Training",
        route: "/pages/landing-pages/coming-soon",
        component: <ComingSoon />,      },
    ],
  },
];
