// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

function Strength() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
        >
          <MKBadge
            variant="contained"
            color="info"
            badgeContent="Unterstützen Sie hier"
            container
            sx={{ mb: 2 }}
          />
          <MKTypography variant="h2" fontWeight="bold">
          Unsere Stärken
          </MKTypography>
          <MKTypography variant="body1" color="text">
          </MKTypography>
        </Grid>
      </Container>
      <Container>
        <Grid container item spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="support"
                  title="Als Schweizer-Tamilisches Hilfswerk kennen wir, sowohl die schweizerischen Anforderungen und Richtlinien für Hilfswerke als auch die kulturellen Gegebenheiten in Sri Lanka."
                  description=" "
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="cached"
                  title="Wir arbeiten sehr effizient und direkt.  "
                  description=" "
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="volunteer_activism"
                  title="Unsere Verwaltungskosten sind dank der ehrenamtlichen Arbeit in der Schweiz sehr gering."
                  description=" "
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="volunteer_activism"
                  title="Wir sind seit Jahren in Sri Lanka tätig und kennen die örtlichen Gegebenheiten."
                  description=" "
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Strength;
