// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';

// Images
import bgFront from "assets/images/rotating-card-bg-front.jpeg";
import bgBack from "assets/images/rotating-card-bg-back.jpeg";
import qr from "assets/images/qr.png";


function Information() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
        >
          <MKBadge
            variant="contained"
            color="info"
            badgeContent="Unterstützen Sie hier"
            container
            sx={{ mb: 2 }}
          />
          <MKTypography variant="h2" fontWeight="bold">
          Wie können Sie uns unterstützen?
          </MKTypography>
          <MKTypography variant="body1" color="text">
          </MKTypography>
        </Grid>
      </Container>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="touch_app"
                title={
                  <>
                    Ganz einfach und schnell
                    <br />
                    uns unterstützen
                  </>
                }
                description="Klicken sie hier um uns ganz einfach über Twint zu spenden"
              />
              <RotatingCardBack
                
                image={bgBack}
                title={
                  <img
                    src={qr} // Replace with the actual path to your image
                    alt="Discover More"
                    style={{ width: "200px", height: "auto" }}  // Adjust size as needed
                  />
                }
                description=" "
                action={{
                  type: "internal",
                  route: "/sections/page-sections/page-headers",
                  label: "Mehr Infos",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="support"
                  title="Spender"
                  description="Sie überweisen uns einen Betrag nach Ihrer Wahl und wir finanzieren damit die laufenden Projekte"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="cached"
                  title="Dauerauftrag"
                  description="Durch einen von Ihnen bestimmten monatlichen Betrag können wir nachhaltig Projekte finanzieren"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="volunteer_activism"
                  title="Zweckgebunden Spenden"
                  description="Sie können auch eine zweckgebundene Spende für ein spezifisches Projekt tätigen. Vermerken Sie Ihre Präferenz bei der Einzahlung!"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
