import urnerwochenblatt from "assets/images/urner-wochenblatt-logo.png";
import nlz from "assets/images/Logo_Neue_Luzerner_Zeitung.svg.png";
import az from "assets/images/Logo-Anzeiger-Luzern.png";


export default [
  {
    image: urnerwochenblatt,
    name: "Mittwoch, 19. November 2014",
    route: "/",
  },
  {
    image: nlz,
    name: "Samstag, 11. Oktober 2014",
    route: "https://www.luzernerzeitung.ch/nachrichten/zentralschweiz/lu/abo/Luzerner-Lehrer-setzt-sich-fuer-Tamilen-ein;art9647,399510",
  },
  {
    image: nlz,
    name: "Dienstag, 22. Juli 2014",
    route: "https://www.luzernerzeitung.ch/nachrichten/zentralschweiz/lu/abo/Luzerner-Lehrer-setzt-sich-fuer-Tamilen-ein;art9647,399510",
  },
  {
    image: az,
    name: "Mittwoch, 30. Juli 2014",
    route: "http://issuu.com/anzeiger-luzern/docs/anzeiger_luzern_nr.30_-_30.7.2014/5?e=10276074/8772385",
  },
];
