// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

function Information() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
        >
          <MKBadge
            variant="contained"
            color="info"
            badgeContent="Unterstützen Sie hier"
            container
            sx={{ mb: 2 }}
          />
          <MKTypography variant="h2" fontWeight="bold">
          Warum STA unterstützen?
          </MKTypography>
          <MKTypography variant="body1" color="text">
            Weil wir...
          </MKTypography>
        </Grid>
      </Container>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="cached"
                  title="...Sri Lanka sehr gut kennen und dort vernetzt sind"
                  description=" "
                />
              </Grid>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="support"
                  title="...seit Jahren erfolgreich Projekte in Sri Lanka realisieren"
                  description=" "
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="cached"
                  title="...Ihre Spenden sehr direkt einsetzen"
                  description=" "
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="volunteer_activism"
                  title="...auf die Unterstützung von vielen Freiwilligen zählen können"
                  description=" "
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="volunteer_activism"
                  title="...wir mit viel Herzblut die Projekte umsetzen"
                  description=" "
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
