// @mui material components
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Divider from "@mui/material/Divider";



// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// ICT page sections
import Contact from "pages/Projects/ICT/sections/Contact";
import Footer from "pages/LandingPages/AboutUs/sections/Footer";

// Routes
import routes from "routes";

// Images
import bgImage from "assets/images/Solid_white-2.png";
import Subtitle from "./sections/Subtitle";
import Counters from "pages/Projects/ICT/sections/Counters";

function ICT() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "/",
          label: "jetzt spenden",
          color: "info",
        }}
        transparent
        light
      />
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.info.main, 0.8),
                rgba(gradients.info.state, 0.8)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <Subtitle />
          <Container sx={{ mt: 6 }}>
        </Container>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                variant="gradient"
                color="info"
                icon="flag"
                title="Projektstandort"
                description="Distrikt Ampara, Akkaraipatthu"
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                color="info"
                icon="schedule"
                title="Projektzeitraum"
                description="1 Jahr (Januar 2020 bis Dezember 2020"
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                color="info"
                icon="savings"
                title="Gesamtprojektkosten"
                description="Rs. 879.100 (umgerechnet 4880 CHF)"
              />
            </Grid>
          </Grid>
        </Container>
          <Counters />
          <Divider sx={{ my: 6 }} />

          <Container>
          <Grid container spacing={3}           justifyContent="center"
          >
          <MKTypography variant="h2">Warum ein ICT Projekt?</MKTypography>
          <Divider sx={{ my: 6 }} />

          <MKTypography variant="h7">Die Menschen, die in tamilischen Dörfern des Distrikts Ampara in der östlichen Provinz Sri Lanka leben, sind wirtschaftlich schlechter gestellt. Außerdem gibt es andere Probleme wie keine Infrastruktureinrichtungen für Schulen, Lehrermangel und keinen Platz für zusätzliche Klassen für die Schüler in den Schulen dieser Dörfer.Abgesehen davon gibt es keine EDV-Ausrüstung, um ICT zu unterrichten. Es fehlt auch an ausgebildete und erfahrene Instruktoren, um diese Ausbildung durchzuführen. Kurzum: ICT Schulung ist dringend notwendig</MKTypography>
          <Divider sx={{ my: 1 }} />

          <MKTypography variant="h7">Dass Computer in der heutigen Gesellschaft und vor allem auch im Berufsleben nicht mehr wegzudenken ist, ist unbestritten. Es ist sogar noch dramatischer: Wer kein Internetzugang hat und oder keine Kenntnisse betreffeend ICT hat, wird in der globalisierten Welt Nachteile erlnagen. Im aufstrebenden Sri Lanka ganz besonders. Die abgelegenen Dörfer des Ampara Distriktes werden den Anschluss verpassen. Aus diesem Grund will STA in einem 1 jährigen Computerkurs die Chancengleichheit gewähren. Die Teilnehmerinnen des Kurses sind vorwiegend Kinder des Amman Kinderheimes, aber auch Frauen der Dörfer des Ampara Distrikts. Ein Anbau des Amman Kinderheimes kann als Unterrichtsraum genutzt werden.Geld ist erforderlich, um dieses Projek umzusetzen. Es bedarf den Kauf zusätzlicher Computer und monatliche Lohnkosten für die Lehrpersonen.</MKTypography>
          <Divider sx={{ my: 1 }} />
          <MKTypography variant="h7">Den Schülerinnen der Klassen 9,10 und 11 des Amman Kinderheims ein ICT-Studium zu ermöglichen ist sehr sinnvoll: Damit werden sie gut ausgebildet und ihre Arbeitsmarktmöglichkeiten steigen.</MKTypography>
          <Divider sx={{ my: 1 }} />
          </Grid>
        </Container>
        </Card>
        <Contact />
        <Footer />
      </MKBox>
    </>
  );
}

export default ICT;
