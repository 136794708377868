// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MKTypography variant="h2" color="info" textGradient mb={2}>
            Über STA
          </MKTypography>
          <MKTypography variant="body1" color="text" mb={2}>
          </MKTypography>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
