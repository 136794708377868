// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

function Newsletter() {
  return (
    <MKBox component="section" pt={2} my={6} alignItems="center">
      <Container>
        <Grid 
          container 
          alignItems="center" 
          justifyContent="center"  // Center horizontally
          textAlign="center"       // Center text content
        >
          <Grid item md={8} xs={12} sx={{ mb: { xs: 12, md: 0 } }}>  {/* Adjust width for centering */}
            <MKTypography variant="h4" mb={2}>
              Möchten Sie informiert werden, sobald unsere Webseite vollständig online ist?
            </MKTypography>
            <MKTypography variant="body2" color="text" mb={3}>
              Gerne senden wir Ihnen eine Benachrichtigung, sobald alle Inhalte und Funktionen verfügbar sind. 
              Hinterlassen Sie einfach Ihre E-Mail-Adresse, und wir halten Sie auf dem Laufenden!
            </MKTypography>
            <Grid container spacing={2} justifyContent="center">  {/* Add spacing between input and button */}
              <Grid item xs={12} sm={8} md={8}>
                <MKInput type="email" label="Ihre Email" fullWidth />
              </Grid>
              <Grid item xs={12} sm={4} md={4} display="flex" justifyContent="center">  {/* Center button */}
                <MKButton variant="gradient" color="info" fullWidth={true} sx={{ height: "100%" }}>
                  Benachrichtigt werden
                </MKButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Newsletter;


