// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

function Counters() {
  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard
              count={25}
              suffix=" Studenten"
              title="Grade 9"
              description=" "
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard
              count={25}
              suffix=" Studenten"
              title="Grade 10"
              description=" "
            />
          </Grid>
          <Grid item xs={12} md={4} display="flex">
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, mx: 0 }} />
            <DefaultCounterCard
              count={25}
              suffix=" Studenten"
              title="Grade 11"
              description=" "
            />
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, ml: 0 }} />
          </Grid>
        </Grid>
      </Container>
      <Container>
      <Grid
          container
          item
          xs={12}
          lg={9}
          justifyContent="center" // This centers the card horizontally
          alignItems="center"     // This centers the card vertically
          sx={{ mx: "auto" }}
        >
          <Grid item xs={12} md={4} display="flex">
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, mx: 0 }} />
            <DefaultCounterCard
              count={75}
              suffix=" Studenten"
              title="TOTAL"
              description=" "
            />
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, ml: 0 }} />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Counters;
