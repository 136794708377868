// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Information from "pages/LandingPages/ComingSoon/sections/Information";
import Team from "pages/LandingPages/ComingSoon/sections/Team";
import Featuring from "pages/LandingPages/ComingSoon/sections/Featuring";
import Newsletter from "pages/LandingPages/ComingSoon/sections/Newsletter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgVideo from "assets/videos/particles-red-background.webm"
function ComingSoon() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "/",
          label: "jetzt spenden",
          color: "default",
        }}
        transparent
        light
      />
      <MKBox
  minHeight="75vh"
  width="100%"
  position="relative"  // Make sure the box is the relative container for the video
  sx={{
    overflow: "hidden",  // Hide any overflow from the video
    display: "grid",
    placeItems: "center",
  }}
>
  <video
    autoPlay
    loop
    muted
    playsInline  // This ensures the video plays inline on mobile devices
    style={{
      position: "absolute",  // Ensures the video covers the background
      top: 0,
      left: 0,
      width: "100%",  // Full width of the container
      height: "100%", // Full height of the container
      objectFit: "cover",  // Ensures the video scales properly to cover the area
      zIndex: -1,  // Keeps the video behind other content
      pointerEvents: "none",  // Prevents interaction with the video (no opening on mobile)
    }}
  >
    <source src={bgVideo} type="video/webm" />
    Your browser does not support the video tag.
  </video>
  <Container>
    <Grid
      container
      item
      xs={12}
      lg={8}
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      sx={{ mx: "auto", textAlign: "center" }}
    >
      <MKTypography
        variant="h1"
        color="white"
        sx={({ breakpoints, typography: { size } }) => ({
          [breakpoints.down("md")]: {
            fontSize: size["3xl"],
          },
        })}
      >
              Demnächst verfügbar
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
            Diese Seite befindet sich im Aufbau. Weitere Informationen zu diesem Projekt finden Sie in unserem Download-Bereich. Dort stehen Ihnen verschiedene Dokumentationen zu den Projekten zum Herunterladen zur Verfügung.
            </MKTypography>
            <MKButton color="default" sx={{ color: ({ palette: { dark } }) => dark.main }}>
              downloads
            </MKButton>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Newsletter />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default ComingSoon;
